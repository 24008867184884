// bg-*
// =========================================================================
section {
	&.show {
		opacity: 1;
		transition: 0.65s ease-in;
	}
	opacity: 0;
}
section.mini-sab,
section.eyecatcher {
	opacity: 1 !important;
}
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
&.home {
	.lead-section {
		.container-holder {
			.wysiwyg {
				display: flex;
				justify-content: center;
				flex-flow: column;
				align-items: center;
				p {
					max-width: 75%;
				}
			}
			text-align: center !important;
			.title h3,
			h2,
			h1 {
				color: $black !important;
			}
		}
	}
}
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 100%;
			margin: 0 auto;
			text-align: left;
			.column {
				padding-left: 0;
			}
		}
	}

	.title,
	.page-title {
		> * {
			color: $blue !important;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			justify-content: flex-start;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.akties-section {
	margin: 6vh 0;
	.container {
		padding: 6vh auto;
	}
	.container:nth-child(1) {
		margin: 6vh auto;
		text-align: center;
		p {
			font-size: $h6-font-size;
			font-weight: 200;
		}
		.container-holder {
			.wysiwyg {
				display: flex;
				justify-content: center;
				flex-flow: column;
				align-items: center;

				p {
					max-width: 75%;
				}
			}
		}
	}
	.container:nth-child(2) {
		h6,
		h5 {
			font-family: $font-family-secondary !important;
			color: $black;
		}
		h4,
		h3,
		h2,
		h1 {
			font-family: $font-family-primary !important;
			color: $teal;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
.impression-section {
	overflow-x: hidden;
	.container-fluid {
		padding: 0;
	}
}

.hero-section {
	margin: 12vh 0;
	.container-holder {
		background-color: $teal;
		border-radius: 12px;
		padding: 2.5rem;
		.one {
			flex-basis: 75%;
			max-width: 75%;
			text-align: center;
			color: $white !important;
			.title h6,
			h5,
			h4,
			h3,
			h2,
			h1 {
				color: $white !important;
			}
		}
		.two {
			flex-basis: 25%;
			max-width: 25%;
			.resource-image {
				display: flex;
				justify-content: center;
			}

			img {
				aspect-ratio: 1/1;
			}
		}
		@include media-breakpoint-down(lg) {
			margin: auto 1rem auto 1rem;
			.one,
			.two {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}
}
