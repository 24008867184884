// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f058";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

ul.home-usps {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	margin: 0 0 6vh 0;
	li {
		font-family: $font-family-secondary;
		font-weight: 600;
		font-size:18px;
		&:before {
			content: "\f14a";
			font-family: $font-awesome;
			color: $green;
			margin-right: 0.35rem;
		}
		@include media-breakpoint-down(lg) {
			margin-top: 0.5rem;
		}
	}
}
