// btn
.btn:not(.card-btn) {
	box-shadow: $shadow;
	font-weight: 700;
	min-width: 194px;
	min-height: 39px;
	font-family: $font-family-secondary;
	border-radius: 28px;

	// btn-primary
	&.btn-primary {
		background-color: $white;
		color: $blue;
		border: 0px;
		&:hover {
			background-color: $blue;
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		background-color: $blue;
		color: $white;
		&:hover {
			background-color: $white;
			color: $blue;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
			background-color: $teal;
		}
	}
	@include media-breakpoint-down(lg) {
		min-width: fit-content;
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
&.accommodation-category-detail {
	.btn-back {
		display: none;
	}
}

.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
