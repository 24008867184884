// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $teal;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			height: 105px;
			transition: 0.45s ease;
		}
	}

	[class^="container-"] {
		.container-holder {
			> .column {
				max-height: 88px;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					align-items: center;
					max-height: auto;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			i {
				color: $white;
			}
			&::after {
				content: "Menu";
				font-size: 10px;
				color: $white;
			}
			button {
				&[aria-expanded="true"] {
					span {
						i {
							&::before {
								content: "\f00d";
							}
						}
					}
				}
			}
		}
	}

	// logo
	.logo {
		margin: 0 1.5rem 10px 1rem;
		background-color: $white;
		width: 283px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		border-radius: 0 0 10px 10px;
		box-shadow: $shadow;
		transition: 0.45s ease;
		@include media-breakpoint-down(lg) {
			width: 200px;
			height: 105%;
		}
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			height: 100%;
			.nav-item {
				border-bottom: 5px solid $teal;
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 1rem;
				transition: 0.35s ease;
				.nav-link {
					color: $white;
				}
				&:hover {
					border-bottom: 5px solid #7ebdc2;
					transition: 0.35s ease;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			order: 5;
			width: 100vw !important;

			padding: 3rem !important;
			background-color: $beige;
			margin: -15px -15px auto -15px !important;
			.nav-item {
				border-bottom: 0 !important;
				width: 100%;
				display: flex;
				flex-flow: row wrap;

				ul.dropdown-menu.show {
					position: relative !important;
					background-color: $beige;
					flex-basis: 100%;
					width: 100%;
					margin-top: 0 !important;
					padding-top: 0 !important;
					transform: none !important;
					.nav-item a {
						font-weight: 200 !important;
						margin-left: 1rem;
					}
				}
			}
			.nav-link {
				color: $teal !important;
				font-weight: 600;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}
		}

		@include media-breakpoint-up(xl) {
		}

		.dropdown-menu.show {
			background-color: $teal;
			border: 0;
			.nav-item {
				border-bottom: 0;
				width: 100%;
				&:hover {
					background-color: rgba($white, 0.15);
				}
			}
			&::before,
			&::after {
				display: none;
			}
		}
	}

	// site-switcher
	.site-switcher {
		display: flex;
		align-items: center;
		margin-left: 1rem;
		@include media-breakpoint-down(lg) {
			float: right;
			position: absolute;
			right: 0.5rem;
			top: 36px;
		}
	}
	div.header-btn {
		display: flex;
		align-items: center;
		a {
			@extend .btn;
			background-color: $blue-dark;
			color: $white;
			&:hover {
				background-color: $blue;
				color: $white;
			}
			span {
				&::before {
					content: "\f002";
					font-family: $font-awesome;
					margin-right: 0.5rem;
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				width: 95px;
				max-height: 23px !important;
				min-height: unset !important;
				font-size: 14px;
				padding: 0;
			}
		}
		@include media-breakpoint-down(lg) {
			position: absolute;
			right: 0.5rem;
			top: 6px;
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
