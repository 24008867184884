// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 85vh;
	}
}

.eyecatcher-owl-carousel.content,
.eyecatcher-owl-carousel.content .item {
	min-height: 70vh !important;
	max-height: 70vh !important;
	height: 70vh !important;
	.owl-title {
		font-size: $h1-font-size;
	}
	.owl-description {
		font-size: $h4-font-size;
	}
	.owl-dots {
		.owl-dot {
			border: 0;
			margin-right: 0.25rem;
			span {
				border: 0;
				border-radius: 1px;
				height: 5px;
				width: 50px;
				background-color: $white;
			}
			&.active span {
				background-color: $teal;
			}
		}
	}
}
