.card {
	border-radius: 12px;
	box-shadow: 0 0 20px #0000002e;
	border: 0;
	.card-image,
	img {
		border-radius: 12px;
	}
	.card-title {
		font-family: $font-family-primary;
		font-size: $h2-font-size !important;
		color: $teal !important;
	}
	.card-subtitle {
		font-size: $h6-font-size !important;
		color: $black !important;
	}
	.card-btn {
		max-width: fit-content;
		box-shadow: none;
		background-color: unset;
		color: $blue-dark;
		text-decoration: underline;
		border: 0;
		padding: 0;
	}
}

.usp-grid {
	padding-bottom: 6vh;
	gap: 1.5rem;
	.item {
		flex-basis: 50% !important;
		max-width: 50% !important;
		width: 50% !important;
		display: flex;
		.card {
			width: 444px;
			min-height: 600px;
		}
		@include media-breakpoint-down(lg) {
			flex-basis: 100% !important;
			max-width: 100% !important;
			justify-content: center!important;
			.card {
				width: 90%;
				min-height: auto;
			}
		}
	}
	.item:nth-child(1) {
		.card {
			background-color: $blue-dark;
			.card-title,
			.card-subtitle,
			.card-description {
				color: $white !important;
			}
			.card-btn {
				@extend .btn, .btn-primary;
				margin: 1rem 0 1rem 0;
				background-color: $white;
				color: $blue;
				font-weight: 700;
				min-width: 194px;
				min-height: 39px;
				font-family: $font-family-secondary;
				border-radius: 28px;
			}
		}
	}
	.item:nth-child(odd) {
		justify-content: flex-end;
	}
	.item:nth-child(even) {
		justify-content: flex-start;
		.card {
			transform: translateY(6vh);
			@include media-breakpoint-down(lg) {
				transform: none;
			}
		}
	}
@include media-breakpoint-down(lg) {
padding-bottom: 0;
}
}
