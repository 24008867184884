// new base file - january 2022

// colors
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport
	.tbs-navbar-default
	.tbs-navbar-nav
	> .tbs-active
	> a:focus,
#TommyBookingSupport
	.tbs-navbar-default
	.tbs-navbar-nav
	> .tbs-active
	> a:hover {
	background-color: $teal;
	color: $white;
}
#TommyBookingSupport .tbs-btn-info {
	background-color: $primary;
	border-color: $primary;
	color: $white;
	border-radius: 28px;
}
#TommyBookingSupport .tbs-btn-info:hover {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}
#TommyBookingSupport .tbs-btn-danger {
	border-radius: 28px;
}
#TommyBookingSupport .tbs-btn-success {
	font-family: $font-family-secondary;
	background-color: $primary;
	border-color: $secondary;
	color: $white;
	border-radius: 28px;
}
#TommyBookingSupport .tbs-btn-success:hover {
	background-color: rgba($primary, 0.8);
	border-color: $primary;
	color: $white;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	background-color: $primary !important;
}

// manually include tommy base styling
// source: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
#TommyBookingSupport .tbs-container-fluid {
	padding: 0;
}
#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
}
#TommyBookingSupport .tbs-navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
}
#TommyBookingSupport * a {
	text-decoration: none;
}
#TommyBookingSupport .tbs-legenda {
	background: none;
	border: none;
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport
	.tbs-row
	.tbs-page-overzicht
	> .tbs-container-fluid
	> .tbs-row:nth-child(2) {
	padding: 20px 0;
	margin: 20px 0;
	background-color: #f8f8f8; // custom
	border-radius: 5px;
}
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 33px 0;
	padding: 20px;
}
#TommyBookingSupport .tbs_overlay-mask {
	border-radius: 5px;
}

#TommyBookingSupport .tbs-calendar {
	margin: 20px 0;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar {
	cursor: pointer;
	transition: all 0.5s ease;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-beschikbaar:hover {
	opacity: 0.8;
}
#TommyBookingSupport .tbs-calendar table > thead > tr > th {
	border: none;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	color: #fff;
	background-color: #105251;
}

#TommyBookingSupport .tbs-row h2 {
	margin-bottom: 0;
}
#TommyBookingSupport .tbs-btn-block + .tbs-btn-block {
	margin: 0;
}
#TommyBookingSupport .tbs_overzicht .tbs-row h2 {
	margin-bottom: 15px;
}

#TommyBookingSupport .tbs_overzicht .tbs-specificaties .tbs-row {
	margin-top: 15px;
}

#TommyBookingSupport .tbs-alert-danger {
	color: #e91e63;
	background-color: rgba(233, 30, 99, 0.09);
	border: none;
	margin: 0 15px 15px;
	padding: 10px 35px 10px 10px;
}
#TommyBookingSupport .tbs-has-error .tbs-form-control {
	border-color: rgb(244, 67, 54);
}

/* kassabon */
#TommyBookingSupport .tbs_kassabon .tbs-row {
	margin-bottom: 15px;
}
#TommyBookingSupport .tbs-label {
	font-size: 100%;
	margin-right: 5px;
}
#TommyBookingSupport .tbs_kassabon .tbs-hr {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span.tbs-translate {
	font-weight: 400;
	font-family: $font-family-primary;
}
#TommyBookingSupport .tbs_kassabon .tbs-totaal span#totaal_amount {
	font-size: 32px;
	line-height: 50px;
}
#TommyBookingSupport .tbs_persoonsgegevens_overzicht > .tbs-row {
	margin: 0 0 15px 0;
}

/* resonsive mobile */
@media (max-width: 767px) {
	#TommyBookingSupport .tbs-calendarPrevious span.tbs-translate,
	#TommyBookingSupport .tbs-calendarNext span.tbs-translate {
		display: none;
	}
}

h4 {
	font-family: $font-family-primary;
	font-size: $h6-font-size;
}
