// footer
.footer {
	.footer-staff-section {
		margin: 6vh 0;

		.container-holder {
			background-color: $blue;
			background-image: url("/images/fam_capelle.png");
			background-repeat: no-repeat;
			background-position: bottom right;
			border-radius: 12px;
			padding: 2.5rem;

			.column {
				flex-basis: 75%;
				max-width: 75%;
				text-align: center;
				color: $white !important;

				h6,
				h5,
				h4,
				h3,
				h2,
				h1 {
					color: $white !important;
				}
				.btn {
					margin-right: 0.5rem;
					margin-bottom: 0.5rem;
				}
				@include media-breakpoint-down(lg) {
					flex-basis: 100%;
					max-width: 100%;
				}
			}
			@include media-breakpoint-down(lg) {
				margin: auto 1rem auto 1rem;
				background-position: bottom center;
				padding-bottom: 250px;
			}
		}
	}
	.footer-links-section {
		margin: 6vh 0;
		h6,
		h5,
		h4,
		h3,
		h2,
		h1 {
			font-family: $font-family-secondary;
			font-size: $h6-font-size;
			color: $black !important;
			font-weight: 600;
		}
		.vocabulary-term-list {
			li {
				margin-right: 0.5rem;
				i {
					color: $teal !important;
					font-size: 20px;
				}
				span {
					display: none;
				}
				&:hover {
					i {
						font-weight: 900;
						color: $black!important;
					}
				}
			}
		}
	}
	.footer-socket .list {
		li {
			margin-right: 1rem;
			a {
				text-decoration: none;
			}
		}
		justify-content: center;
	}
}
