// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #59979C;
$green-dark: #3d4609;
$blue: #476C9B;
$blue-dark: #32537D;
$white: #fff;
$black: #101419;
$teal:#59979C;
$beige:#F8F3E3;


// theme-colors
$primary: $blue;
$secondary: $green-dark;
$tertiary: $blue;

// body
$body-bg: $beige;
$body-color: $black;

// links
$link-color: $blue-dark;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@300;400;700&display=swap');
$font-family-primary: 'Sofia Sans', sans-serif;
$font-family-secondary: 'Edu VIC WA NT Beginner', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.375; // 22px
$h6-font-size: $font-size-base * 1.25; // 20px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $blue;
$dark: $blue-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;

// shadow
$shadow: 0 0 20px #0000002E;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
*/
