// mini-sab
&.home {
	.mini-sab {
		.title,
		.wysiwyg {
			display: block;
		}
	}
}
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: $primary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			border-radius: 10px;
			color: #fff;
			.title,
			.wysiwyg {
				text-align: center;
				h2 {
					color: $white !important;
				}
			}
			// below eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	margin-top: -50px;
			// 	padding-left: 30px;
			// 	padding-right: 30px;
			// }

			// above eyecatcher

			position: absolute;
			left: auto;
			right: auto;
			bottom: -2rem;
			@include media-breakpoint-down(lg) {
				position: relative;
				margin: 1.5rem;
				margin-top: -12vh;
			}
		}
	}
	.title,
	.wysiwyg {
		display: none;
	}
}
